import React from 'react';
import { Chart } from 'primereact/chart';
import Paper from '@material-ui/core/Paper';

export default class PassByHourChart extends React.Component {
    render() {
        var labels = [],
            data = [];

        for (var i = 0; i < 24; i++) {
            labels.push(i);
            data.push(0);
        }

        var chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: 'Passagens por horario',
                    backgroundColor: "#42A5F5",
                }
            ]
        }

        const chartOptions = {
            legend: {
                labels: {
                    fontColor: '#495057',
                    fontSize: 15,
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057',
                        fontSize: 15,
                    }
                }]
            },
            maintainAspectRatio: false
        };


        this.props.passageList.forEach(function (passage) {
            var datetime = (new Date(passage.datetime));
            data[datetime.getHours()]++;
        });

        return (
            <Paper>
                <h2>Passagens por Horário</h2>
                <Chart type="bar" data={chartData}  options={chartOptions} height={'400%'} />
            </Paper>
        )
    }
}
