import { Chart } from 'primereact/chart';
import React from 'react'
import Paper from '@material-ui/core/Paper';


export default class PassByMonthChart extends React.Component {
    render() {
        var labels = [],
            data = [];

        var total = 0;
        this.props.dailyData.forEach(function (row) {
            var signal = (new Date()).getTimezoneOffset() > 0 ? '-' : '+',
                hourOffset = Math.abs((new Date()).getTimezoneOffset() / 60),
                hourOffsetStr = hourOffset < 10 ? '0' + hourOffset : hourOffset,
                dStr = row.date + " 00:00" + signal + hourOffsetStr + ":00",
                d = new Date(dStr),
                label = d.toLocaleString('default', {'month': 'long'}) + '-' + d.getFullYear();

            if (-1 === labels.indexOf(label)) {
                labels.push(label);
                data.push(total);
                total = parseInt(row.qty);
            } else {
                total += parseInt(row.qty);
            }
        })

        data.push(total);
        data.shift();
        console.log(data);

        var chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: 'Passagens por Mês',
                    backgroundColor: "#42A5F5",
                }
            ]
        };


        const chartOptions = {
            legend: {
                labels: {
                    fontColor: '#495057',
                    fontSize: 15,
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057',
                        fontSize: 15,
                    }
                }]
            },
            maintainAspectRatio: false
        };

        return (
            <Paper>
                <h2>Passagens por Mês</h2>
                <Chart type="bar" data={chartData}  options={chartOptions} height={'400%'} />
            </Paper>
        )

    }
}
