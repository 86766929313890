import axios from 'axios';
import { Chart } from 'primereact/chart';
import config from '../config';
import React from 'react'
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

export default class UserStatsChart extends React.Component {
    state = {
        userData: [],
        loading: true,
    };

    fetchData() {
        var bearer = null;

        if (!localStorage.getItem('user')) {
            return;
        }

        try {
            bearer = JSON.parse(localStorage.getItem('user')).jwt;
        } catch (e) {
            console.log("Unexpected localStorage user item format");
            console.log(e);
            return;
        }

        axios.get(
            config.API_URL + '/passmon/user',
            {
                'headers': {
                    'ACCEPT': 'application/vnd.coldchain.v2+json',
                    'Authorization': 'Bearer ' + bearer
                }
            }
        )
        .catch(err => {
            // TODO: unify this code with other axios calls.
            console.log(err.response)
            if ('undefined' === typeof err.response) {
                return Promise.reject(err);
            }

            if ('undefined' === typeof err.response.status) {
                return Promise.reject(err);
            }

            if (err.response.status === 401 || err.response.status === 403) {
                console.log("REDIRECT TO LOGIN");
                localStorage.removeItem('user');
                this.props.toLogin();
            }

            return Promise.reject(err);
        })
        .then(res => {
            console.log(res.data)
            const userData = res.data;

            this.setState({
                userData: userData,
                loading: false,
            });
            this.props.setData(userData);

            return res;
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        var labels = [],
            dataAll = [],
            dataActive = [],
            dataActive7 = [],
            dataActive14 = [];
            //dataActive45 = [],
            //dataActive60 = [];

        this.state.userData.forEach(function (row) {
            var date = new Date(),
                dateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            if (row.date == dateStr) {
                return;
            }
            labels.push(row.date);
            dataAll.push(parseInt(row.all));
            dataActive.push(parseInt(row.active))
            dataActive7.push(parseInt(row.active7))
            dataActive14.push(parseInt(row.active14))
            //dataActive45.push(parseInt(row.active45))
            //dataActive60.push(parseInt(row.active60))
        });

        var chartData = {
            labels: labels,
            datasets: [
                {
                    data: dataActive7,
                    label: 'Usuários Ativos (Leitura nos últimos 7 dias)',
                    backgroundColor: "#2222aa",
                },

                {
                    data: dataActive14,
                    label: 'Usuários Ativos (Leitura nos últimos 14 dias)',
                    backgroundColor: "#22aaaa",
                },

                {
                    data: dataActive,
                    label: 'Usuários Ativos (Leitura nos últimos 30 dias)',
                    backgroundColor: "#aa2222",
                },
                /*{
                    data: dataActive45,
                    label: 'Usuários Ativos (Leitura nos últimos 45 dias)',
                    backgroundColor: "#FFA726",
                },
                {
                    data: dataActive60,
                    label: 'Usuários Ativos (Leitura nos últimos 60 dias)',
                    backgroundColor: "#66BB6A",
                },*/


                {
                    data: dataAll,
                    label: 'Usuários com Leitura',
                    backgroundColor: "#42A5F5",
                },


            ]
        }

        const chartOptions = {
            legend: {
                labels: {
                    fontColor: '#495057',
                    fontSize: 15,
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057',
                        fontSize: 15,
                    }
                }]
            },
            maintainAspectRatio: false
        };


        return (
            <Paper>
                <h2>Evolução do Total de Usuários</h2>
                <Chart type="line" data={chartData}  options={chartOptions} height={'400%'} />
            </Paper>
        )
    }
}
UserStatsChart.propTypes = {
    toLogin: PropTypes.func,
    setData: PropTypes.func,
}
