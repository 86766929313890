import axios from 'axios';
import { Chart } from 'primereact/chart';
import config from '../config';
import React from 'react'
import Paper from '@material-ui/core/Paper';


export default class PassByDayChart extends React.Component {


    state = {
        qtyData: [],
        loading: true,
    };

    fetchData() {
        var bearer = null;

        if (!localStorage.getItem('user')) {
            return;
        }

        try {
            bearer = JSON.parse(localStorage.getItem('user')).jwt;
        } catch (e) {
            console.log("Unexpected localStorage user item format");
            console.log(e);
            return;
        }

        axios.get(
            config.API_URL + '/passmon/qtyuserqtypass',
            {
                'headers': {
                    'ACCEPT': 'application/vnd.coldchain.v2+json',
                    'Authorization': 'Bearer ' + bearer
                }
            }
        )
        .catch(err => {
            // TODO: unify this code with other axios calls.
            if ('undefined' === typeof err.response) {
                return Promise.reject(err);
            }

            if ('undefined' === typeof err.response.status) {
                return Promise.reject(err);
            }

            if (err.response.status === 401 || err.response.status === 403) {
                console.log("REDIRECT TO LOGIN");
                localStorage.removeItem('user');
                this.props.toLogin();
            }

            return Promise.reject(err);
        })
        .then(res => {
            const qtyData = res.data;

            this.setState({
                qtyData: qtyData,
                loading: false,
            });

            return res;
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        var labels = [],
            data = [],
            threshold = 7,
            total = 0;

        
        this.state.qtyData.forEach(function (row) {
            total += parseInt(row.qty_user);
        });

        this.state.qtyData.forEach(function (row) {
            if (labels.length < threshold) {
                labels.push("Passagens: " + row.qty_pass + " | Usuários " + Math.floor(100 * parseInt(row.qty_user) / total) + "%");
                data.push(parseInt(row.qty_user));
            } else if (labels.length === threshold) {
                labels.push(">= " + row.qty_pass);
                data.push(parseInt(row.qty_user));
            } else {
                data[data.length - 1] += parseInt(row.qty_user);
            }
        });

        labels[labels.length - 1] = "Passagens: " + labels[labels.length - 1] + " | Usuários " + Math.floor(100 * data[data.length - 1] / total) + "%"

        var chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: 'Qtd de Usuários por Qtd de Passagens',
                    backgroundColor: [
                        "#42A5F5",
                        "#66BB6A",
                        "#FFA726",
                        "#aa2222",
                        "#22aaaa",
                        "#2222aa",
                        "#aa22aa",

                        "#92F505",



                    ]
                }
            ]
        }

        const chartOptions = {
            legend: {
                labels: {
                    fontColor: '#495057',
                    fontSize: 15,
                }
            },
            maintainAspectRatio: false,
            showDataLabels: true,
            position: 'left'
        };


        return (
            <Paper>
                <h2>Qtd de Usuários por Qtd de Passagens</h2>
                <Chart type="pie" data={chartData}  options={chartOptions} height={'400%'} />
            </Paper>
        )
    }
}
