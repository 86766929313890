import React from 'react';
import PassageTable from './PassageTable';
import './Main.css';
import "date-fns";
import { Button } from "@material-ui/core";
import soundFile from '../assets/chaching.aac';
import PassageForm from './PassageForm';
import DayPieChart from './DayPieChart';
import PassByHourChart from './PassByHourChart';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';


export default class Main extends React.Component {
    state = {
        timestamp: this.getDayTimestamp(new Date())[1],
        date: this.getDayTimestamp(new Date())[0],
        playChaChing: false,
        haveGrantSpeakerPermission: this.props.haveGrantSpeakerPermission,
        passageList: [],
    };

    componentDidMount() {
        this.setDate(new Date());
    }

    getDayTimestamp(date) {
        var dateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + " 00:00:00",
            timestamp;

        date = new Date(dateStr);
        timestamp = parseInt(date.getTime() /  1000)

        return [dateStr, timestamp];
    }

    setDate(date) {
        var dateStr, timestamp;

        [dateStr, timestamp] = this.getDayTimestamp(date);
        this.setState({date: dateStr, timestamp: timestamp});
    }

    onNewPass() {
        console.log("onNewPass called");
        if (!this.state.haveGrantSpeakerPermission) {
            return;
        }
        this.playChaChing();
    }

    setPassageList(passageList) {
        this.setState({passageList: passageList});
    }

    playChaChing() {
        console.log("Playing cha ching");
        this.setState({playChaChing: true})
        setTimeout(() => this.turnOffChaChing(), 2000);
    }

    turnOffChaChing() {
        this.setState({playChaChing: false});
    }

    handleSpeakerPermission() {
        this.setState({haveGrantSpeakerPermission: true});
        this.props.setSpeakerPermission(true);
        this.playChaChing();
    }

    toLogin() {
        this.setState({toLogin: true})
    }

    render () {
        if (this.state.toLogin) {
            return <Redirect to="/login"/>;
        }

        return (
        <div className="Main">
            <h1>Dashboard de Acompanhamento do Dia</h1>
            { this.state.haveGrantSpeakerPermission ? null :
            <Button variant="contained" color="primary" onClick={() => this.handleSpeakerPermission()}>Grant Speaker Permission</Button>
            }
                
            <PassageForm date={this.state.date} onChange={(date) => this.setDate(date)} />
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item sm={6}>
                    <DayPieChart passageList={this.state.passageList} />
                </Grid>
                <Grid item sm={6}>
                    <PassByHourChart passageList={this.state.passageList} />
                </Grid>
                <Grid item xs={12}>
                    <PassageTable timestamp={this.state.timestamp} onNewPass={() => this.onNewPass()} setPassageList={(passageList) => this.setPassageList(passageList)} toLogin={() => this.toLogin()}/>
                </Grid>
            </Grid>
            { this.state.playChaChing ? 
                <audio src={soundFile} autoPlay /> : null
            }
        </div>
        );
    }
}


