import React from 'react';
import Grid from '@material-ui/core/Grid';
import './Consolidated.css';
import PassByDayChart from './PassByDayChart';
import QtyUserQtyPassChart from './QtyUserQtyPassChart';
import PassByMonthChart from './PassByMonthChart';
import UserStatsChart from './UserStatsChart';
import UserFirstUseChart from './UserFirstUseChart';
import DayPieChart from './DayPieChart';
import { Redirect } from 'react-router-dom';


export default class Consolidated extends React.Component {
    state = {
        data: [],
        userData: [],
        toLogin: false,
    };

    setData(data) {
        this.setState({data: data});
    }

    setUserData(data) {
        this.setState({userData: data});
    }

    toLogin() {
        this.setState({toLogin: true})
    }

    render () {
        if (this.state.toLogin) {
            return <Redirect to="/login"/>;
        }

        return (
            <div className="Consolidated">
                <div><h1>Dashboard Consolidado</h1></div>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item sm={12}>
                        <PassByDayChart setData={(data) => this.setData(data)} toLogin={() => this.toLogin()}/>
                    </Grid>
                    <Grid item sm={4}>
                        <DayPieChart dailyData={this.state.data} />
                    </Grid>
                    <Grid item sm={4}>
                        <PassByMonthChart dailyData={this.state.data} />
                    </Grid>
                    <Grid item sm={4}>
                        <QtyUserQtyPassChart userData={this.state.userData} />
                    </Grid>

                    <Grid item sm={12}>
                        <UserStatsChart setData={(data) => this.setUserData(data)} toLogin={() => this.toLogin()}/>
                    </Grid>
                    <Grid item sm={12}>
                        <UserFirstUseChart userData={this.state.userData} />
                    </Grid>


                </Grid>
            </div>
        )
    }
}
