import React from 'react';
import { Chart } from 'primereact/chart';
import Paper from '@material-ui/core/Paper';

export default class DayPieChart extends React.Component {
    render() {
        var labels = [],
            data = [],
            passageTotal = [];

        if ('undefined' !== typeof this.props.passageList) {
            this.props.passageList.forEach (function(passage) {
                if (-1 === labels.indexOf(passage.plaza_name)) {
                    labels.push(passage.plaza_name);
                }
            });

            labels.forEach (function (label) {
                passageTotal.push({'label': label, 'data': 0});
            });
            
            this.props.passageList.forEach(function (passage) {
                var index = passageTotal.findIndex((ele) => ele.label === passage.plaza_name);
                passageTotal[index].data += 1;
            });

        } else if ('undefined' !== typeof this.props.dailyData) {
            this.props.dailyData.forEach(function (row) {
                if (-1 === labels.indexOf(row.plaza_name)) {
                    labels.push(row.plaza_name);
                }
            });

            labels.forEach (function (label) {
                passageTotal.push({'label': label, 'data': 0});
            });

            this.props.dailyData.forEach(function (row) {
                var index = passageTotal.findIndex((ele) => ele.label === row.plaza_name);
                passageTotal[index].data += parseInt(row.qty);
            });


        }

        passageTotal.sort((a, b) => b.data - a.data);

        labels = [];

        for (var i = 0; i < passageTotal.length; i++) {
            labels.push(passageTotal[i].label);
            data.push(passageTotal[i].data);
        }


        const chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: [
                        "#42A5F5",
                        "#66BB6A",
                        "#FFA726",
                        "#aa2222",
                        "#22aaaa",
                        "#2222aa",
                        "#aa22aa",
                    ]
                }
            ]
        };

        const chartOptions = {
            legend: {
                labels: {
                    fontColor: '#495057',
                    fontSize: 15,
                    render: 'label',
                }
            },
            maintainAspectRatio: false
        };


        return (
            <Paper>
                <h2>Passagens por Praça</h2>
                <Chart type="pie" dataFormat={'percent'} data={chartData} options={chartOptions} height={"400%"}/>
            </Paper>
        )
    }
}
