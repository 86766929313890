import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../config';
import Form from "react-bootstrap/Form";
import { handleResponse } from '../helpers/handleResponse';
import "./Login.css"
import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import { useAppContext } from "../libs/contextLib";
import { Button, Box, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { userHasAuthenticated } = useAppContext();
    const [alert, setAlert] = useState(false);

    function login(username, password) {
        const requestOptions = {
            method: 'POST',
            headers:
            {
                "Content-Type": "application/json",
                "Accept": "application/vnd.coldchain.v2+json",
            },
            body: JSON.stringify({
                username: username,
                password: password,
            })
        };

        return fetch(config.API_URL + '/login', requestOptions)
            .then(handleResponse)
            .then(user => {
                localStorage.setItem('user', JSON.stringify(user));
                userHasAuthenticated(true);
                return user;
            })
            .catch(err => {
                console.log('error logging in');
                setAlert('Wrong Password');
            });
    }

    function validateForm() {
        return email.length > 0 && password.length >0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        return login(email, password);
    }

    function getAlert() {
        return alert;
    }

    if (localStorage.getItem('user')) {
        return <Redirect to="/" />;
    }


    return (
        <div className="Login">
            { getAlert() && <Alert severity="warning">{getAlert()}</Alert> }
            <Paper>
                <Box m={10}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group size="lg" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                autoFocus
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group size="lg" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="contained" color="primary" type="submit" disabled={!validateForm()}>
                            Login
                        </Button>
                    </Form>
                </Box>
            </Paper>
        </div>
    );
}
