import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import axios from 'axios';
import ReactLoading from 'react-loading';
import './PassageTable.css';
import config from '../config';

export default class PassageTable extends React.Component {
    newestId = 0;

    state = {
        passageList: [],
        count: 0,
        timestamp: this.props.timestamp,
        intervalFetchId: 0,
        loading: true,
    };

    fetchData() {
        var bearer = null;

        if (!localStorage.getItem('user')) {
            return;
        }

        try {
            bearer = JSON.parse(localStorage.getItem('user')).jwt;
        } catch (e) {
            console.log("Unexpected localStorage user item format");
            console.log(e);
            return;
        }

        axios.get(
            config.API_URL + '/passmon?timestamp=' + this.props.timestamp,
            {
                'headers': {
                    'ACCEPT': 'application/vnd.coldchain.v2+json',
                    'Authorization': 'Bearer ' + bearer
                }
            }
        )
        .catch(err => {
            // TODO: unify this code with other axios calls.
            if ('undefined' === typeof err.response) {
                return Promise.reject(err);
            }

            if ('undefined' === typeof err.response.status) {
                return Promise.reject(err);
            }

            if (err.response.status === 401 || err.response.status === 403) {
                console.log("REDIRECT TO LOGIN");
                localStorage.removeItem('user');
                this.props.toLogin();
            }

            return Promise.reject(err);
        })
        .then(res => {
            const passageList = res.data;

            for (var i = 0; i < passageList.length; i++) {
                passageList[i].datetime = (new Date(passageList[i].datetime + "+00:00")).toLocaleString();
                var newestId = passageList[i].id;
            }

            this.setState({
                passageList: passageList,
                count: passageList.length,
                loading: false,
            });

            if (this.newestId !== newestId) {
                this.props.onNewPass();
                this.props.setPassageList(this.state.passageList);
            }
            this.newestId = newestId;

            return res;
        })
    }

    componentDidMount() {
        var intervalFetchId = setInterval(() => this.fetchData(), 20000);
        this.setState({intervalFetchId: intervalFetchId});
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.timestamp !== this.props.timestamp) {
            this.setState({loading: true});
            this.fetchData();
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalFetchId);
    }

    createData(id, dateTime, obuid, sls) {
        return {id, dateTime, obuid, sls};
    }

    render() {
        const classes = makeStyles({
            table: {
                minWidth: 650,
            },
            tableHeadCell: {
                fontWeight: 'bold',
            }
        });

        var i = this.state.count;

        return (
            <Paper>
                <h2>Lista de Passagens</h2>
                <TableContainer component={Paper}>
                    { this.state.loading ? <ReactLoading className="ReactLoading" type="bars" color="black"  height="10%" width="10%" /> : null }
                    { this.state.loading ? null : 
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeadCell}>#</TableCell>
                                <TableCell className={classes.tableHeadCell}>Data/Hora</TableCell>
                                <TableCell className={classes.tableHeadCell}>OBUID</TableCell>
                                <TableCell className={classes.tableHeadCell}>SLS</TableCell>
                                <TableCell className={classes.tableHeadCell}>Praça</TableCell>
                                <TableCell className={classes.tableHeadCell}>Direção</TableCell>
                                <TableCell className={classes.tableHeadCell}>Pista</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.passageList.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{i--}</TableCell>
                                    <TableCell>{row.datetime}</TableCell>
                                    <TableCell>{row.value}</TableCell>
                                    <TableCell>{row.identifier}</TableCell>
                                    <TableCell>{row.plaza_name}</TableCell>
                                    <TableCell>{row.lane_direction}</TableCell>
                                    <TableCell>{row.lane_number}</TableCell>

                                        
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    }
                </TableContainer>
            </Paper>
        )
    }
}
