import axios from 'axios';
import { Chart } from 'primereact/chart';
import config from '../config';
import React from 'react'
import Paper from '@material-ui/core/Paper';


export default class PassByDayChart extends React.Component {


    state = {
        dailyData: [],
        loading: true,
    };

    fetchData() {
        var bearer = null;

        if (!localStorage.getItem('user')) {
            return;
        }

        try {
            bearer = JSON.parse(localStorage.getItem('user')).jwt;
        } catch (e) {
            console.log("Unexpected localStorage user item format");
            console.log(e);
            return;
        }

        axios.get(
            config.API_URL + '/passmon/daily?tzoffset=' + (new Date()).getTimezoneOffset(),
            {
                'headers': {
                    'ACCEPT': 'application/vnd.coldchain.v2+json',
                    'Authorization': 'Bearer ' + bearer
                }
            }
        )
        .catch(err => {
            // TODO: unify this code with other axios calls.
            if ('undefined' === typeof err.response) {
                return Promise.reject(err);
            }

            if ('undefined' === typeof err.response.status) {
                return Promise.reject(err);
            }

            if (err.response.status === 401 || err.response.status === 403) {
                console.log("REDIRECT TO LOGIN");
                localStorage.removeItem('user');
                this.props.toLogin();
            }

            return Promise.reject(err);
        })
        .then(res => {
            const dailyData = res.data;

            this.setState({
                dailyData: dailyData,
                loading: false,
            });
            this.props.setData(dailyData);

            return res;
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        var labels = [],
            data = [];

        this.state.dailyData.forEach(function (row) {
            if (-1 === labels.indexOf(row.date)) {
                labels.push(row.date);
                data.push(parseInt(row.qty));
            } else {
                data[data.length - 1] += parseInt(row.qty);
            }
        });

        var chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: 'Passagens por Dia',
                    backgroundColor: "#42A5F5",
                }
            ]
        }

        const chartOptions = {
            legend: {
                labels: {
                    fontColor: '#495057',
                    fontSize: 15,
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057',
                        fontSize: 15,
                    }
                }]
            },
            maintainAspectRatio: false
        };


        return (
            <Paper>
                <h2>Passagens por Dia</h2>
                <Chart type="bar" data={chartData}  options={chartOptions} height={'400%'} />
            </Paper>
        )
    }
}
