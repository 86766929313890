import { AppBar, Toolbar, Button, Typography, Tab, Tabs, Box, Grid } from '@material-ui/core';
import { AppContext } from './libs/contextLib';
import './App.css';
import Consolidated from './components/Consolidated';
import Login from './components/Login';
import Main from './components/Main';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(localStorage.getItem('user') ? true : false);
  const [value, setValue] = useState(0);
  const classes = useStyles();
  let history = useHistory();
  const [haveGrantSpeakerPermission, setSpeakerPermission] = useState(false);

  const redirectLogin = () => {
      history.push('/login');
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const redirectLogout = () => {
      localStorage.removeItem('user');
      userHasAuthenticated(false);
      redirectLogin();
  }

  const isLoggedIn = () => {
      return localStorage.getItem('user');
  }

  if ('/' === window.location.pathname && ! isAuthenticated) {
      redirectLogin();
  }

  return (
    <div className={classes.root}>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <AppBar position="static">
          <Toolbar>
            <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
                <Typography variant="h6" className={classes.title}>
                    VBL - Sem Parar Pay - Dashboard
                </Typography>

                { isAuthenticated &&
                <Tabs value={value} onChange={handleChange} aria-label="">
                  <Tab label="Acompanhamento do Dia" {...a11yProps(0)} />
                  <Tab label="Consolidado" {...a11yProps(1)} />
                </Tabs>
                }
                <Button color="inherit" onClick={redirectLogin} hidden={isLoggedIn()}>Login</Button>
                <Button color="inherit" onClick={redirectLogout} hidden={!isLoggedIn()}>Logout</Button>
            </Grid>
          </Toolbar>
        </AppBar>

        { isAuthenticated && <div>
          <TabPanel value={value} index={0}>
            <Main setSpeakerPermission={(e) => setSpeakerPermission(e)} haveGrantSpeakerPermission={haveGrantSpeakerPermission}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Consolidated/>
          </TabPanel>
            </div>
        }
          <Switch>
            <Route path="/login" component={Login}/>
          </Switch>
      </AppContext.Provider>
    </div>
  );
}

export default App;
