import { Chart } from 'primereact/chart';
import React from 'react'
import Paper from '@material-ui/core/Paper';


export default class UserFirstUseChart extends React.Component {
    state = {
        userData: [],
        loading: true,
    };

    render() {
        var labels = [],
            data = [],
            pastDay = 0;

        this.props.userData.forEach(function (row) {
            var date = new Date(),
                dateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
                currentDay = parseInt(row.all);
            labels.push(row.date);
            data.push(currentDay - pastDay);
            pastDay = currentDay;
            if (row.date === dateStr) {
                return;
            }

        });

        labels.pop();
        data.shift();

        var chartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    label: 'Qtd de Usuários que Realizaram Primeira Leitura',
                    backgroundColor: "#42A5F5",
                },
            ]
        }

        const chartOptions = {
            legend: {
                labels: {
                    fontColor: '#495057',
                    fontSize: 15,
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057',
                        fontSize: 15,
                    }
                }]
            },
            maintainAspectRatio: false
        };


        return (
            <Paper>
                <h2>Primeiro Uso em Praça</h2>
                <Chart type="bar" data={chartData}  options={chartOptions} height={'400%'} />
            </Paper>
        )
    }
}
